import React from "react";
import './Footer.css';

function Footer() {
    return (
        <div id="div-footer-container">
            <p id="p-footer-text">Site development and design by Luke Gosnell</p>
        </div>
    );
}

export default Footer;