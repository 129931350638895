import React from "react";
import './NotFound.css';

function NotFound() {
    return(
        <div id="notFound">
            <h1>404</h1>
            <p>Page not found ;-;</p>
        </div>
    );
}

export default NotFound;