import React from "react";
import './OurFamily.css';

function OurFamily() {
    return(
        <div id="ourFamily">
            <h1>Our Family</h1>
            <p>Page not found ;-;</p>
        </div>
    );
}

export default OurFamily;