import HomeComponent from '../components/home-component/HomeComponent';

function Home() {
    return(
        <div>
            <HomeComponent/>
        </div>
    );
}

export default Home;